import { jwtDecode } from "jwt-decode";

interface DecodedToken {
  exp: number;
  iat: number;
}

interface AuthData {
  address: string;
  token: string;
}

// Storing the token and address in local storage
export const storeAuthDataInLocalStorage = (authData: AuthData) => {
  localStorage.setItem('mintpad_auth', JSON.stringify(authData));
};

// Retrieving the token and address from local storage
export const getAuthDataFromLocalStorage = (): AuthData | null => {
  const authDataString = localStorage.getItem('mintpad_auth');
  return authDataString ? JSON.parse(authDataString) : null;
};

// Removing the token and address from local storage
export const removeAuthDataFromLocalStorage = () => {
  localStorage.removeItem('mintpad_auth');
};

// Check if the user is authenticated and token is not expired
export const isUserAuthenticated = (currentAddress: string): boolean => {
  const authData = getAuthDataFromLocalStorage();
  if (!authData || !authData.token || authData.address !== currentAddress) return false;

  try {
    const decoded: DecodedToken = jwtDecode(authData.token);
    const currentTime = Date.now() / 1000; // convert milliseconds to seconds
    if (decoded.exp < currentTime) {
      // Token has expired
      removeAuthDataFromLocalStorage(); // Optional: automatically clear expired token
      return false;
    }
    return true;
  } catch (error) {
    console.error("Failed to decode token", error);
    removeAuthDataFromLocalStorage(); // Clear potentially corrupted auth data
    return false;
  }
};

// Helper function to create headers for API calls
export const createAuthHeaders = () => {
    const authData = getAuthDataFromLocalStorage();
    return {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authData?.token}`,
    };
};
