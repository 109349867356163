import Link from 'next/link'
import { Button, Flex, Text } from './primitives'
import { useIsUnsupportedChain } from 'hooks'
import { useTheme } from 'next-themes'

const UnsupportedChainBanner = (): JSX.Element => {
  const { unsupportedChain, isWrongNetwork, preferredNetwork, switchNetworkHandler} = useIsUnsupportedChain()

  const { theme } = useTheme()

  return (
    <>
      {isWrongNetwork && (
        <Flex
          css={{
            px: '40px',
            py: '12px',
            background: theme === 'dark' ? '$violet6' : '$violet9',
          }}
        >
          <Text
            style="body2"
            css={{
              color: 'White',
            }}
          >
            <>
              Your wallet is currently connected to {' '}
              {unsupportedChain?.name || 'an unsuported network'}. Please switch to{' '}
               <Button onClick={switchNetworkHandler} size={'medium'} color={'ghost'} 
                css={{minHeight:20, maxHeight:20, p:0}}>
                <Text
                  css={{
                    color: 'White',
                    textDecoration: 'underline',
                  }}
                  style="body2"
                >
                  {preferredNetwork?.name}
                </Text>
              </Button>
            </>
          </Text>
        </Flex>
      )}
    </>
  )
}

export default UnsupportedChainBanner
