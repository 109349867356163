import React, { FC } from 'react'
import { styled } from '../../stitches.config'
import { StyledComponent } from '@stitches/react/types/styled-component'
import { zeroAddress } from 'viem'

type Props = {
  address: string
  chainId?: number
} & Parameters<StyledComponent>['0']

const StyledImg = styled('img', {})

const CryptoCurrencyIcon: FC<Props> = ({
  address = zeroAddress,
  chainId,
  css,
}) => {

  return (
    <StyledImg
      src={`/icons/currency/${address}.png`}
      css={css}
    />
  )
}

export default CryptoCurrencyIcon