import { useEffect, useState } from 'react'
import chains from 'utils/chains'
import { Chain } from 'viem'
import { useAccount, useSwitchChain } from 'wagmi'

export default () => {
  const [unsupportedChain, setUnsupportedChain] = useState<Chain | undefined>(
    undefined,
  )
  const { chain, address } = useAccount()
  const { switchChain } = useSwitchChain()
  const supportedNetworkId = Number(process.env.NEXT_PUBLIC_CHAIN_ID);
  const isWrongNetwork = address && chain?.id !== supportedNetworkId

  useEffect(() => {
    if (isWrongNetwork) {
        setUnsupportedChain(chain)
    } else {
      setUnsupportedChain(undefined) // Reset to undefined if chain matches the supported chain ID
    }
  }, [chain])

  const preferredNetwork = chains.find((chain) => chain.id === supportedNetworkId)
    // Function to switch network
    const switchNetworkHandler = () => {
      switchChain({ chainId: supportedNetworkId})
    }

  return {
    unsupportedChain,
    isWrongNetwork,
    preferredNetwork,
    switchNetworkHandler
  }
}
