import { Anchor, Box, Button, Flex, Text } from 'components/primitives'
import { Avatar } from 'components/primitives/Avatar'
import * as RadixDialog from '@radix-ui/react-dialog'
import {
  faBars,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import Image from 'next/image'
import { useAccount, useDisconnect } from 'wagmi'
import { ConnectWalletButton } from 'components/ConnectWalletButton'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import { FullscreenModal } from 'components/common/FullscreenModal'
import { useENSResolver } from 'hooks'
import ThemeSwitcher from 'components/navbar/ThemeSwitcher'
import useCroId from 'hooks/useCronosID'
import { Address, Chain } from 'viem'
import { faDiscord, faXTwitter } from '@fortawesome/free-brands-svg-icons'

const HamburgerMenu = () => {
  const { address, isConnected, chain} = useAccount()
  const {
    avatar: ensAvatar,
  } = useENSResolver(address)
  const { disconnect } = useDisconnect()

  const {shortEnsName, shortAddress} = useCroId(address as Address, chain as Chain)

  const trigger = (
    <Button
      css={{ justifyContent: 'center', width: '44px', height: '44px' }}
      type="button"
      size="small"
      color="ghost"
    >
      <FontAwesomeIcon size={'xl'} icon={faBars} width={28} height={28} />
    </Button>
  )

  return (
    <FullscreenModal trigger={trigger}>
      {' '}
      <Flex
        css={{
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Flex
          css={{
            py: '$4',
            px: '$4',
            width: '100%',
            borderBottom: '1px solid $gray4',
          }}
          align="center"
          justify="between"
        >
          <Link href="/">
            <Box css={{ width: 46, cursor: 'pointer' }}>
              <Image
                src="/logo.png"
                width={36}
                height={36}
                alt="Mintpad Logo"
              />
            </Box>
          </Link>
          <RadixDialog.Close>
            <Flex
              css={{
                justifyContent: 'center',
                width: '44px',
                height: '44px',
                alignItems: 'center',
                borderRadius: 8,
                backgroundColor: '$ghost',
                color: '$gray12',
                '&:hover': {
                  backgroundColor: '$gray4',
                },
              }}
            >
              <FontAwesomeIcon icon={faXmark} width={16} height={16} />
            </Flex>
          </RadixDialog.Close>
        </Flex>
        {isConnected ? (
          <Flex
            css={{
              flexDirection: 'column',
              justifyContent: 'flex-start',
              height: '100%',
              py: '$5',
              px: '$4',
            }}
          >
            
              <Flex
                css={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer',
                  pb: '$4',
                }}
              >
                <Flex css={{ alignItems: 'center' }}>
                  {ensAvatar ? (
                    <Avatar size="medium" src={ensAvatar} />
                  ) : (
                    <Jazzicon
                      diameter={36}
                      seed={jsNumberForAddress(address as string)}
                    />
                  )}
                  <Text style="subtitle1" css={{ ml: '$2' }}>
                    {shortEnsName ? shortEnsName : shortAddress}
                  </Text>
                </Flex>
              </Flex>
            
            {/*
            <Link href={`/${routePrefix}/collection-rankings`} legacyBehavior>
              <Text
                style="subtitle1"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                }}
              >
                Explore
              </Text>
            </Link>
            <Link href="/cronos/collections/trending" legacyBehavior>
              <Text
                style="subtitle1"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                }}
              >
                Trending Collections
              </Text>
            </Link>

            <Link href="/cronos/mints/trending" legacyBehavior>
              <Text
                style="subtitle1"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                }}
              >
                Trending Mints
              </Text>
            </Link>
            */}
            <Link href="/presale" legacyBehavior>
              <Flex
                direction="column"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                  gap: '$1',
                }}
              >
                <Text style="subtitle1">Token Presale</Text>
              </Flex>
            </Link>
            <Link href="/mint" legacyBehavior>
              <Flex
                direction="column"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                  gap: '$1',
                }}
              >
                <Text style="subtitle1">NFT Launchpad</Text>
              </Flex>
            </Link>
            <Link href="/nft-staking" legacyBehavior>
              <Flex
                direction="column"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                  gap: '$1',
                }}
              >
                <Text style="subtitle1">NFT Staking</Text>
                <Text style="body3" color="subtle">
                  Stake your NFTs for daily rewards
                </Text>
              </Flex>
            </Link>
            <Link href="/profile?tab=presale" legacyBehavior>
              <Flex
                direction="column"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                  gap: '$1',
                }}
              >
                <Text style="subtitle1">Create Presale</Text>
              </Flex>
            </Link>
            {/*
            

          <Collapsible
            trigger={
              <Flex
                justify="between"
                css={{
                  width: '100%',
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                }}
              >
                <Text style="subtitle1">More</Text>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  width={20}
                  height={20}
                  style={{
                    marginLeft: 10, // Add some space between the text and the icon
                  }}
                />
              </Flex>
            }
          >
            <Flex
              direction="column"
              css={{
                backgroundColor: '$gray2',
                px: '$4',
              }}
            >
          <Link href={`https://app.aragon.org/#/daos/polygon/0xc44c2e2714a4a9fb171ecc2ec7a472b692d66ac6/dashboard`} legacyBehavior>
            <Text
              style="subtitle1"
              css={{
                borderBottom: '1px solid $gray4',
                cursor: 'pointer',
                pb: '$4',
                pt: '24px',
              }}
            >
              DAO Governance
            </Text>
          </Link>
              <Link href={`https://nftearth.exchange/inscriptions`} legacyBehavior>
            <Text
              style="subtitle1"
              css={{
                borderBottom: '1px solid $gray4',
                cursor: 'pointer',
                pb: '$4',
                pt: '24px',
              }}
            >
              Inscriptions
            </Text>
          </Link>
          <Link href={`https://nftearth.exchange/raffle`} legacyBehavior>
            <Text
              style="subtitle1"
              css={{
                borderBottom: '1px solid $gray4',
                cursor: 'pointer',
                pb: '$4',
                pt: '24px',
              }}
            >
              Raffle
            </Text>
          </Link>
              <Link href="/fortune" legacyBehavior>
                <Text
                  style="subtitle1"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                  }}
                >
                  Fortune
                </Text>
              </Link>
              <Link
                href="https://smartnft.nftearth.exchange"
                target="_blank"
                legacyBehavior
              >
                <Text
                  style="subtitle1"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                  }}
                >
                  SmartNFT
                </Text>
              </Link>
            </Flex>
          </Collapsible>
            <Link href="/portfolio" legacyBehavior>
              <Flex
                direction="column"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                  gap: '$1',
                }}
              >
                <Text style="subtitle1">Portfolio</Text>
                <Text style="body3" color="subtle">
                  Manage your items, collections, listings and offers
                </Text>
              </Flex>
            </Link>
            <Wallet />

              */}
            <Button
              size="large"
              css={{ my: '$4', mt:'$5', justifyContent: 'center' }}
              color="gray3"
              onClick={() => disconnect()}
            >
              Disconnect Wallet
            </Button>
          </Flex>
        ) : (
          <Flex
            direction="column"
            justify="between"
            css={{
              height: '100%',
              pb: '$5',
              px: '$4',
            }}
          >
            <Flex direction="column">
              <Link href="/presale" legacyBehavior>
                <Flex
                  direction="column"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                    gap: '$1',
                  }}
                >
                  <Text style="subtitle1">Presales</Text>
                </Flex>
              </Link>
              <Link href="/mint" legacyBehavior>
                <Flex
                  direction="column"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                    gap: '$1',
                  }}
                >
                  <Text style="subtitle1">NFT Launchpad</Text>
                </Flex>
              </Link>
              <Link href="/nft-staking" legacyBehavior>
                <Flex
                  direction="column"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                    gap: '$1',
                  }}
                >
                  <Text style="subtitle1">NFT Staking</Text>
                  <Text style="body3" color="subtle">
                    Stake your NFTs for daily rewards
                  </Text>
                </Flex>
              </Link>
            {/*
              <Link href="/" legacyBehavior>
                <Text
                  style="subtitle1"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                    width: '100%',
                  }}
                >
                  Explore
                </Text>
              </Link>
              <Link href={`/portfolio/${address || ''}`} legacyBehavior>
                <Text
                  style="subtitle1"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                    width: '100%',
                  }}
                >
                  Portfolio
                </Text>
              </Link>
              <Anchor
                href="https://docs.mintpad.network/docs"
                target="_blank"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                  width: '100%',
                }}
              >
                <Text style="subtitle1">Docs</Text>
              </Anchor>
              */}
            </Flex>
            <Box>
              <ConnectWalletButton />
            </Box>
          </Flex>
        )}
        <Flex
          css={{
            pt: '24px',
            pb: '$5',
            px: '$4',
            gap: '$4',
            width: '100%',
            borderTop: '1px solid $gray4',
          }}
        >
          <a href="https://twitter.com/mintpadNetwork" target="_blank">
            <Button
              css={{ justifyContent: 'center', width: '44px', height: '44px' }}
              type="button"
              size="small"
              color="gray3"
            >
              <FontAwesomeIcon icon={faXTwitter} width={20} height={20} />
            </Button>
          </a>
          <a href="https://discord.gg/6V5t5MkT" target="_blank">
            <Button
              css={{ justifyContent: 'center', width: '44px', height: '44px' }}
              type="button"
              size="small"
              color="gray3"
            >
              <FontAwesomeIcon icon={faDiscord} width={20} height={20} />
            </Button>
          </a>
        </Flex>

        <Flex
          css={{
            pt: '$1',
            pb: '$2',
            px: '$4',
            width: '100%',
          }}
        >
          <ThemeSwitcher />
        </Flex>
      </Flex>
    </FullscreenModal>
  )
}

export default HamburgerMenu