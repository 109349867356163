import React from 'react';
import { keyframes, styled } from '../../stitches.config';
import { Text } from './index'

const pulsate = keyframes({
    '0%': { transform: 'rotate(30deg) scale(1)', opacity:0.6 },
    '100%':{ transform: 'rotate(30deg) scale(4)', opacity:0 },
});

  const Price = styled('div', {
    position: 'relative',
    display: 'flex',
    width: '30px',
    padding: '30px',
    alignItems:'center',
    marginTop: 2,
  });

  const PriceWrapper = styled('span', {
    fontSize: '16px',
    fontWeight: 700,
    pointerEvents: 'none',
    position: 'absolute',
  
    '&:before': {
      content: '⬢',
      color: '#081c31',
      transform: 'rotate(30deg)',
      position: 'absolute',
      fontSize: '16px',
      left: '-18px',
    },
  
    '&:after': {
      content: '⬢',
      position: 'absolute',
      fontSize: '14px',
      color: '#2773b8',
      top:1,
      left: '-17px',
      animationName: `${pulsate}`, // Pass an empty object or your animation properties
      animationDuration: '2s',
      animationIterationCount: 'infinite',
    },
  });


const MenuBarPrice = ({ amount }:any) => {
  return (
    <Price>
      <PriceWrapper>
        <Text style={'subtitle2'} css={{marginLeft:5, color:'$gray11'}}>${amount}</Text>
      </PriceWrapper>
    </Price>
  );
};

export default MenuBarPrice;